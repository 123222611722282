<template>
    <div>
        <custom-vue-table class="ml-2 mr-2" title="Group" :fields="fields" :url="listUrl" :per-page="10" ref="table">
            <template #filters>
                <btn icon="fa fa-plus" class="ml-3" size="md" @click="$refs.addModal.show()" text="Add"/>
            </template>

            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)"/>
                    <edit-btn @click="setEdit(rowData)"/>
                </div>
            </template>
        </custom-vue-table>

        <modal no-close-on-backdrop title="Add Group" ref="addModal" width="30r">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                <validated-input label="Code" v-model="model.code" :rules="rules.code"
                                 :disabled="loading"/>
                <validated-input label="Name" v-model="model.name" :rules="rules.name"
                                 :disabled="loading"/>
                <btn icon="" text="Save" size="sm" :loading="loading" loading-text="Saving..."/>
            </b-form>
        </modal>

        <modal no-close-on-backdrop title="Edit Group" ref="editModal" width="30r">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :initial-data="editingItem">
                <validated-input label="Code" v-model="model.code" :rules="rules.code"
                                 :disabled="loading"/>
                <validated-input label="Name" v-model="model.name" :rules="rules.name"
                                 :disabled="loading"/>
                <btn icon="" text="Update" size="sm" :loading="loading" loading-text="Updating..."/>
            </b-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the Group Are you sure ?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '@/data/urls';

export default {
    name : 'Group',
    data () {
        return {
            showAddForm  : false,
            listUrl      : urls.salesAdmin.MasterData.Group.list,
            addUrl       : urls.salesAdmin.MasterData.Group.addEdit,
            deleteUrl    : urls.salesAdmin.MasterData.Group.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'code',
                    sortField : 'code',
                    title     : 'Code'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ],
            rules : {
                code : {
                    required : true
                },
                name : {
                    required : true
                }
            }
        };
    },
    methods : {
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
