var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-vue-table',{ref:"table",staticClass:"ml-2 mr-2",attrs:{"title":"Group","fields":_vm.fields,"url":_vm.listUrl,"per-page":10},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('btn',{staticClass:"ml-3",attrs:{"icon":"fa fa-plus","size":"md","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}})]},proxy:true},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[_c('delete-btn',{on:{"click":function($event){return _vm.setDelete(rowData)}}}),_c('edit-btn',{on:{"click":function($event){return _vm.setEdit(rowData)}}})],1)]}}])}),_c('modal',{ref:"addModal",attrs:{"no-close-on-backdrop":"","title":"Add Group","width":"30r"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"label":"Code","rules":_vm.rules.code,"disabled":loading},model:{value:(model.code),callback:function ($$v) {_vm.$set(model, "code", $$v)},expression:"model.code"}}),_c('validated-input',{attrs:{"label":"Name","rules":_vm.rules.name,"disabled":loading},model:{value:(model.name),callback:function ($$v) {_vm.$set(model, "name", $$v)},expression:"model.name"}}),_c('btn',{attrs:{"icon":"","text":"Save","size":"sm","loading":loading,"loading-text":"Saving..."}})]}}])})],1),_c('modal',{ref:"editModal",attrs:{"no-close-on-backdrop":"","title":"Edit Group","width":"30r"}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"initial-data":_vm.editingItem},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"label":"Code","rules":_vm.rules.code,"disabled":loading},model:{value:(model.code),callback:function ($$v) {_vm.$set(model, "code", $$v)},expression:"model.code"}}),_c('validated-input',{attrs:{"label":"Name","rules":_vm.rules.name,"disabled":loading},model:{value:(model.name),callback:function ($$v) {_vm.$set(model, "name", $$v)},expression:"model.name"}}),_c('btn',{attrs:{"icon":"","text":"Update","size":"sm","loading":loading,"loading-text":"Updating..."}})]}}])})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('delete-animation'),_vm._v(" Please wait while we delete. ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the Group Are you sure ?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }